"use client";
import Select from "@/components/primitives/select";
import Icon from "@/components/primitives/icon";
import BettorDisplay from "../bettor/bettor-display";
import { GameAlignmentType, Sport, TeamBasic, TopPicker } from "common";
import { FOLLOWER } from "@/data/follower";
import React from "react";
import { useWebSocketMessage } from "@/utils/useWebSocketMessage";
import { ALL_SPORT_OPTIONS } from "@/data/sports/options";
import { calculatePercentage } from "@/utils";
import NotFound from "../primitives/not-found";
import { LoadingCircle } from "../primitives/loading";
import useFetch from "@/utils/fetch";
import { createLeaderboardSlug, getTeamNameUrl } from "@/utils/url";
import TeamDisplay from "../team/display";
import Card from "../primitives/card";
import Button from "../primitives/button";

interface TopPickersProps {
  idKey?: number;
  sport?: Sport;
  game?: {
    id: number;
    awayTeam: TeamBasic;
    homeTeam: TeamBasic;
  };
  team?: TeamBasic;
}

interface GameTopPickers {
  home: TopPicker[];
  away: TopPicker[];
}
const cols = ["Username", "W/L/T", "Win %", "Units"];
export default function TopPickers(props: TopPickersProps) {
  const [selectedSport, setSelectedSport] = React.useState<Sport | "all">(
    props.sport || "all"
  );
  const [timeframe, setTimeframe] = React.useState<string>(props.team || props.game ? "last25Games" : "last25GamesLast25Days");

  // const wsLeaderboard = useWebSocketMessage("leaderboard");
  // console.log("leaderboard from ws:", wsLeaderboard);
  // const baseUrl: string = `${process.env.NEXT_PUBLIC_API_URL}/api/users/top-pickers`;
  const baseUrl: string = `${process.env.NEXT_PUBLIC_API_URL}/bettors/leaderboard`;
  const params: URLSearchParams = new URLSearchParams();
  params.append("timeframe", timeframe);
  if (props.game) {
    params.append("sport", `${props.sport}`);
    params.append("gameId", `${props.game.id}`);
  } else if (props.team) {
    params.append("sport", `${props.sport}`);
    params.append("teamId", `${props.team.id}`);
  } else {
    if (selectedSport !== "all") {
      params.append("sport", selectedSport);
    }
  }
  // if (props.game) {
  //   params.append("team", getTeamNameUrl(props.game.homeTeam));
  //   params.append("opponent", getTeamNameUrl(props.game.awayTeam));
  // } else if (props.team) {
  //   params.append("team", props.team.abbreviation);
  // }
  params.append("limit", "10");
  const request: string = `${baseUrl}?${params}`;
  const { data, isLoading, isError, error } = useFetch<
    (any) | null
  >(null, request, []);
  // console.log("TOP PICKERS REQUEST:", request);
  console.log("TOP PICKERS DATA", data);
  console.log("top pickers game", props.game);
  return (
    <>
      <Card
        header={{
          team: props.team?.name,
          title: (
            <>
              <Icon name="flame" gradient="flame" size="md" idKey={props.idKey || 0}/>

              <span>
                {props.game ? "" : props.team ? "" : props.sport && props.sport} Top Pickers
              </span>
            </>
          ),
          action: (
            <div className="flex items-center gap-1">
              {!props.sport && !props.team && (
                <Select
                  label="Sports"
                  options={ALL_SPORT_OPTIONS}
                  defaultValue={selectedSport}
                  variant="card"
                  onValueChange={(v) => setSelectedSport(v as Sport | "all")}
                />
              )}
              <Select
                label="Window"
                align="end"
                options={[
                  {
                    label: "Last 25",
                    value: props.team || props.game ? "last25GamesLast25Days" : "last25Games",
                  },
                  {
                    label: "Last 100",
                    value: props.team || props.game ? "last100GamesLast100Days" : "last100Games",
                  },
                ]}
                defaultValue={timeframe}
                variant="card"
                onValueChange={(v) => setTimeframe(v)}
              />
              <Button variant="card" href={createLeaderboardSlug(selectedSport)}>
                View All
              </Button>
            </div>
          ),
        }}
      >
        <div className="table-container">
        {!isLoading && (!data || !data?.bettors || data?.bettors.length === 0) ? (
              <NotFound label="No top pickers yet" icon="ticket-buy" />

          ) : isLoading ? (
            <div className="flex justify-center items-center h-[220px]">
              <LoadingCircle />
            </div>
          ) : props.game !== undefined ? (
            <div className="flex flex-col gap-6">
                <div className="flex flex-col">
                  <TeamDisplay
                    sport={selectedSport}
                    variant="abbreviation"
                    size="sm"
                    team={props.game.awayTeam}
                  />
                  {data?.bettors[GameAlignmentType.Away] ? (
                    <TopPickersTable data={data?.bettors[GameAlignmentType.Away]} />
                  ) : (
                    <NotFound label="No top pickers yet" icon="ticket-buy" />
                  )}
                </div>
                <div className="flex flex-col">
                  <TeamDisplay
                    sport={selectedSport}
                    variant="abbreviation"
                    size="sm"
                    team={props.game.homeTeam}
                  />
                  {data?.bettors[GameAlignmentType.Home]  ? (
                    <TopPickersTable data={data?.bettors[GameAlignmentType.Home] } />
                  ) : (
                    <NotFound label="No top pickers yet" icon="ticket-buy" />
                  )}
                </div>
              </div>
          ) : (
            <TopPickersTable data={data?.bettors} />

          )}
        </div>
      </Card>
    </>
  );
}

function TopPickersTable({ data }: { data: TopPicker[] }) {
  return (
    <table className="banded w-full">
      <thead>
        <tr className="text-label-sm-medium text-secondary-500 uppercase whitespace-nowrap">
          <th>Username</th>
          <th>W/L/T</th>
          <th className="hidden desktop-grid:block">Win%</th>
          <th>Units</th>
        </tr>
      </thead>

      <tbody>
        {data?.map((picker, i) => (
          <tr key={i} className="text-title-sm-medium">
            <td>
              <div className="flex items-center gap-2 py-0">
                <BettorDisplay bettor={picker} noCard/>
              </div>
            </td>
            <td>
              <p className="whitespace-nowrap">
                {picker.wins}-{picker.losses}-{picker.ties}
              </p>
            </td>
            <td className="h-[56px] items-center hidden desktop-grid:flex">
              <div className="inline-flex gap-0 items-center -ml-[5px]">
                {picker.wins > picker.losses ? (
                  <Icon name="arrow-button-up" className="fill-success" />
                ) : (
                  <Icon name="arrow-button-down" className="fill-error" />
                )}{" "}
                {calculatePercentage(picker.wins, picker.wins + picker.losses)}%
              </div>
            </td>
            <td>
              <p className="whitespace-nowrap">
                {picker.totalUnits > 0 ? `+${picker.totalUnits}` : picker.totalUnits}
              </p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
